import React from "react";
import "./Partners.css";
import apa from "./img/apa.png";
import first from "./img/first.png";
import heritage from "./img/heritage.jpeg";
import kenya from "./img/kenya.png";
import madison from "./img/madison.jpeg";
import pioneer from "./img/pioneer.jpeg";
import liberty from "./img/liberty.png";
import old from "./img/old.png";
import icea from "./img/icea.png";
import sanlam from "./img/sanlam.png";
import ga from "./img/ga.png";
import orient from "./img/orient.jpeg";
import fidelity from "./img/fidelity.jpeg";

export default function Partners() {
  return (
    <section id="partners" className="clients">
      <div className="container" data-aos="fade-up">
        <div className="section-header">
          <h2>Partners</h2>
        </div>

        <div
          className="row no-gutters clients-wrap clearfix"
          data-aos="fade-up"
        >
          <div className="col-lg-4 col-md-4 col-6">
            <div className="client-logo">
              <img src={apa} className="img-fluid" alt="lema partner" />
            </div>
          </div>

          <div className="col-lg-4 col-md-4 col-6">
            <div className="client-logo">
              <img src={first} className="img-fluid" alt="lema partner" />
            </div>
          </div>

          <div className="col-lg-4 col-md-4 col-6">
            <div className="client-logo">
              <img src={heritage} className="img-fluid" alt="lema partner" />
            </div>
          </div>

          <div className="col-lg-4 col-md-4 col-6">
            <div className="client-logo">
              <img src={kenya} className="img-fluid" alt="lema partner" />
            </div>
          </div>

          <div className="col-lg-4 col-md-4 col-6">
            <div className="client-logo">
              <img src={madison} className="img-fluid" alt="lema partner" />
            </div>
          </div>

          <div className="col-lg-4 col-md-4 col-6">
            <div className="client-logo">
              <img src={pioneer} className="img-fluid" alt="lema partner" />
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-6">
            <div className="client-logo">
              <img src={old} className="img-fluid" alt="lema partner" />
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-6">
            <div className="client-logo">
              <img src={icea} className="img-fluid" alt="lema partner" />
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-6">
            <div className="client-logo">
              <img src={liberty} className="img-fluid" alt="lema partner" />
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-6">
            <div className="client-logo">
              <img src={sanlam} className="img-fluid" alt="lema partner" />
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-6">
            <div className="client-logo">
              <img src={ga} className="img-fluid" alt="lema partner" />
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-6">
            <div className="client-logo">
              <img src={orient} className="img-fluid" alt="lema partner" />
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-6">
            <div className="client-logo">
              <img src={fidelity} className="img-fluid" alt="lema partner" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
