import React from "react";
import Services from "../../sections/services/Services";

export default function ServicesPage() {
  return (
    <div style={{ marginTop: "5vh" }}>
      <Services />
    </div>
  );
}
