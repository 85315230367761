import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Services.css";

export default function Services({ scrollToSection }) {
  const [isCommercial, setIsCommercial] = useState(true);
  const [isPersonal, setIsPersonal] = useState(false);
  const [isPension, setIsPension] = useState(false);

  // const [hoveredItemPosition, setHoveredItemPosition] = useState(null); // State to hold hovered item's position

  const emailBody = `Dear sir/madam\n\nI am <Your Full Name>, and I am interested in getting quotation/details for ${
    (isCommercial && "Corporate Insurance") ||
    (isPersonal && "Personal Insurance") ||
    (isPension && "Pensions & Investments")
  }. You can reach me via phone at <include country code> or by email at <your email>.\n\nWarm regards.\n<Your Name>`;
  const recipientEmail = "info@lemainsurance.com";
  const emailSubject = `REQUEST FOR DETAILS/ QUOTE ON ${
    (isCommercial && "CORPORATE INSURANCE") ||
    (isPersonal && "PERSONAL INSURANCE") ||
    (isPension && "PENSIONS & INVESTMENTS")
  }`;

  const setState = (state) => {
    setIsCommercial(false);
    setIsPersonal(false);
    setIsPension(false);
    state(true);
  };

  const handleHover = (event) => {
    // const listItem = event.target;
    // const listItemRect = listItem.getBoundingClientRect();
    // const serviceMoreInfo = document.querySelector(".service-more-info");
    // const serviceMoreInfoRect = serviceMoreInfo.getBoundingClientRect();
    // const top = listItemRect.top + serviceMoreInfoRect.height;
    // setHoveredItemPosition({ top: top, left: listItemRect.left });
  };

  const [isHome, setisHome] = useState(false);
  useEffect(() => {
    window.location.pathname === "/#" || window.location.pathname === "/"
      ? setisHome(true)
      : setisHome(false);
  }, []);
  const commercialItems = [
    {
      title: "All Risks",
      more: " Comprehensive coverage for specified valuable items against all risks.",
    },
    {
      title: "Burglary",
      more: "Insurance against theft involving forcible entry.  ",
    },
    { title: "Business Combined", more: "" },
    {
      title: "Carriers Liability",
      more: "Protection for carriers against liability for loss or damage to goods during transportation.  ",
    },
    {
      title: "Contractors All risks",
      more: "Comprehensive coverage for contractors against loss or damage to construction projects, including materials, tools, and third-party liability.",
    },
    {
      title: "Contractos Plant & Machinery",
      more: "Insurance for plant and machinery used by contractors, covering damage, theft, and liability.  ",
    },
    {
      title: "Contractual Liability",
      more: "Coverage for liabilities assumed by the insured under contractual agreements.  ",
    },
    { title: "Custom Bond", more: "" },
    {
      title: "Directors & Officers Liability",
      more: "Safeguards directors and officers from personal losses due to decisions made in their official capacity.  ",
    },
    {
      title: "Electronic Equipment",
      more: "Protection for electronic devices and equipment against risks like accidental damage, breakdown, or loss.  ",
    },
    {
      title: "Employers Liability",
      more: "Coverage for legal liabilities arising from injuries or illnesses to employees during work.  ",
    },
    {
      title: "Erection All Risks",
      more: " Coverage for the erection/installation phase of machinery or structures, protecting against various risks.  ",
    },
    {
      title: "Fidelity Guarantee",
      more: "Coverage for financial losses due to employee dishonesty.  ",
    },
    {
      title: "Fire Consequential Loss",
      more: "Protects against financial losses resulting from business interruption due to fire damage.  ",
    },
    {
      title: "Goods in Transit",
      more: "Protects goods against damage or loss during transportation by road.  ",
    },
    {
      title: "Group Personal Accident",
      more: "Coverage for groups against accidental injuries or death.  ",
    },
    {
      title: "Industrial All Risks",
      more: "Comprehensive coverage for industrial properties, including assets, machinery, and liability.  ",
    },
    {
      title: "Machinery Breakdown",
      more: "Coverage for sudden and unforeseen damage to machinery, ensuring minimal business interruption.  ",
    },
    {
      title: "Machinery Consequential loss",
      more: "Provides financial protection against losses resulting from machinery breakdown, including business interruption.  ",
    },
    {
      title: "Marine Cargo Air,Sea,Open",
      more: "Coverage for goods in transit by sea, air, or open-air, protecting against damage or loss.  ",
    },
    {
      title: "Marine Hull",
      more: "Insurance for the vessel and machinery of ships against various risks.  ",
    },
    {
      title: "Money",
      more: "Protection for money in transit, on premises, or in a bank.  ",
    },
    {
      title: "Motor Commercial General Cartage",
      more: "Coverage for commercial vehicles engaged in general cartage activities.  ",
    },
    {
      title: "Motor Commerical Special Vehicles",
      more: "Insurance for special-purpose commercial vehicles.  ",
    },
    { title: "Motor Cycle insurance", more: "" },
    {
      title: "Motor Private, Commercial Own Goods,Motor Trade",
      more: "Various motor insurance types covering private vehicles, commercial vehicles, and those used in the motor trade.  ",
    },
    {
      title: "PSV Chauffeur Drive",
      more: "Public service vehicles insurance for chauffeur-driven vehicles.  ",
    },
    {
      title: "PSV Motor Asset",
      more: "This insurance provides coverage for a range of risks associated with public service vehicles and aims to protect the assets of the owners or operators.  ",
    },
    {
      title: "Pension Trustees Liability",
      more: "Protects trustees of pension funds against liabilities arising from their trustees duties.  ",
    },
    { title: "Performance bond", more: "" },
    {
      title: "Petroleum Tankers Own Goods",
      more: "Insurance for petroleum tankers carrying own goods or hired for reward.  ",
    },
    { title: "Petroleum Tankers for Hire / Reward", more: "" },
    { title: "Plate Glass", more: "" },
    { title: "Political Violence & Terrorism", more: "" },
    {
      title: "Products Liability",
      more: "Protects against legal claims for injuries or damage caused by products.  ",
    },
    {
      title: "Professional Indemnity",
      more: "Insurance for professionals against claims arising from errors, omissions, or negligence.  ",
    },
    {
      title: "Public Liability",
      more: "Protection against legal liabilities arising from injury or property damage to third parties.  ",
    },
    { title: "Security/Immigration Bond", more: "" },
    {
      title: "Stock Floater",
      more: "Covers stock and inventory against various risks such as fire, theft, or damage.  ",
    },
    { title: "Tender/Bid Bond", more: "" },
    { title: "Travel Insurance", more: "" },
    { title: "Work Injury Benefits Act", more: "" },
  ];

  const personalItems = [
    {
      title: "All Risks",
      more: "Comprehensive coverage for personal belongings, including items like jewelry, electronics, and other valuable possessions, against all risks.    ",
    },
    {
      title: "Burglary",
      more: "Coverage for losses resulting from theft or forced entry into a residence, compensating for stolen items and property damage.    ",
    },
    {
      title: "Fire & Special Perils",
      more: "Protection against losses caused by specific perils such as fire, lightning, explosion, earthquakes, and other named risks.    ",
    },
    {
      title: "Fire Domestic Cover",
      more: "Insurance for homeowners, providing coverage for damages caused by fire to the structure and contents of the insured property.    ",
    },
    {
      title: "Home Insurance",
      more: "Protection for your home and its contents against risks like fire, theft, natural disasters, and other specified perils.    ",
    },
    {
      title: "Marine Cargo Sea/ Air Insurance",
      more: "Coverage for personal goods during transit by sea or air, safeguarding against damage, theft, or loss.    ",
    },
    {
      title: "Medical cover for small non-corporate groups",
      more: "Health insurance designed for small groups, such as community organizations or clubs, offering coverage for medical expenses.    ",
    },
    {
      title: "Motor Commercial Insurance Including Oil Tankers",
      more: "Insurance for commercial vehicles, including specialized coverage for oil tankers, protecting against accidents and other risks.    ",
    },
    {
      title: "Motor Private Insurance",
      more: "Comprehensive coverage for personal vehicles, protecting against accidents, theft, and various other risks.    ",
    },
    {
      title: "PSV Insurance",
      more: "Public Service Vehicle insurance for personal vehicles used for public transportation, ensuring compliance with legal requirements.    ",
    },
    {
      title: "Personal Accident Insurance",
      more: "Coverage providing financial compensation in the event of accidental injuries, disabilities, or death.",
    },
    {
      title: "Personal Health Cover",
      more: "Comprehensive health insurance providing coverage for medical expenses, hospitalization, and other healthcare-related costs for individuals.    ",
    },
    {
      title: "Private Group Health Cover",
      more: "Health insurance tailored for groups, often offered by employers, providing coverage for the medical needs of employees or members.    ",
    },
    {
      title: "Private Motor Cycle Insurance",
      more: "Insurance specifically designed for personal motorcycles, providing coverage for accidents, theft, and damage.    ",
    },
    {
      title: "Professional Indemnity",
      more: "Insurance for professionals (e.g., consultants, lawyers) against legal liabilities arising from errors, omissions, or professional negligence.    ",
    },
    { title: "Travel Insurance", more: "" },
    {
      title: "Work Injuries Cover for Domestic Workers",
      more: "Insurance covering domestic workers against injuries or accidents that occur while performing their job duties.    ",
    },
  ];

  return (
    <div className="services" id="services">
      <div className="section-header">
        <h2>Services We Offer</h2>
      </div>
      <div className="services-container">
        <div className="services-tabs ">
          <div
            className={`services-tabs-option ${isCommercial && "active"} `}
            onClick={() => setState(setIsCommercial)}
          >
            Corporate Insurance
          </div>
          <div
            className={`services-tabs-option ${isPersonal && "active"} `}
            onClick={() => setState(setIsPersonal)}
          >
            Personal Insurance
          </div>
          <div
            className={`services-tabs-option ${isPension && "active"} `}
            onClick={() => setState(setIsPension)}
          >
            Pensions & Investments
          </div>
        </div>
        <div className="services-content">
          <div className="services-left">
            {isCommercial && (
              <div className="commercial">
                <p>
                  Corporate Insurance is a type of insurance coverage designed
                  to protect businesses and organizations from financial losses
                  resulting from various risks and liabilities. It provides
                  financial compensation for damages, losses, or legal
                  liabilities that may arise during the course of business
                  operations
                </p>
                <ul>
                  {commercialItems.sort().map(({ title, more }, index) => (
                    <li
                      key={index}
                      onMouseEnter={handleHover}
                      // onMouseLeave={() => setHoveredItemPosition(null)}
                    >
                      <Link
                        style={{ textDecoration: "none", color: "black" }}
                        to="/services"
                      >
                        <span style={{ color: "#3f386a", fontWeight: "600" }}>
                          {title}
                        </span>
                        {`${more.length > 0 ? `: ${more}` : "."}`}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {isPersonal && (
              <div className="personal">
                <p>
                  Personal Insurance it is a type of insurance that has products
                  designed to protect individuals and their families from
                  financial losses or hardships resulting from unforeseen
                  circumstances.
                </p>
                <ul>
                  {personalItems.map(({ title, more }, index) => (
                    <li
                      key={index}
                      onMouseEnter={handleHover}
                      // onMouseLeave={() => setHoveredItemPosition(null)}
                    >
                      <Link
                        style={{ textDecoration: "none", color: "black" }}
                        to={"/services"}
                      ></Link>
                      <span style={{ color: "#3f386a", fontWeight: "600" }}>
                        {title}
                      </span>
                      {`${more.length > 0 ? `: ${more}` : "."}`}
                      <Link />
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {isPension && (
              <div className="pension">
                <p>
                  Plan for a secure retirement with our pension management
                  services. We offer personalized solutions to help you build a
                  robust pension fund, ensuring financial stability during your
                  golden years
                </p>
                <p>
                  Grow your wealth with our diverse range of investment
                  products. Whether you're looking for long-term growth or
                  short-term gains, our investment options cater to various risk
                  appetites and financial goals.
                </p>
              </div>
            )}
          </div>
          <div
            className="services-right"
            style={{
              backgroundImage: `url( ${
                (isCommercial &&
                  "https://images.pexels.com/photos/730547/pexels-photo-730547.jpeg?auto=compress&cs=tinysrgb&w=800") ||
                (isPersonal &&
                  "https://images.pexels.com/photos/7550283/pexels-photo-7550283.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load") ||
                (isPension &&
                  "https://images.pexels.com/photos/13073688/pexels-photo-13073688.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load")
              })`,
            }}
          ></div>
        </div>
      </div>
      <a
        className="section-btn"
        href={`mailto:${recipientEmail}?subject=${emailSubject}&body=${encodeURIComponent(
          emailBody
        )}`}
        target={"_blank"}
        rel="noreferrer"
      >
        {`Get Quote for ${
          (isCommercial && "Commercial Insurance") ||
          (isPersonal && "Personal Insurance") ||
          (isPension && "Pension Insurance")
        }`}
      </a>
    </div>
  );
}
