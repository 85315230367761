import React from "react";
import "./Whyus.css";
import expert from "../../assets/img/expert.svg";
import afford from "../../assets/img/afford.svg";
import innovate from "../../assets/img/innovate.svg";
import honest from "../../assets/img/honest.svg";
import client from "../../assets/img/client.svg";

export default function Whyus() {
  const card = (h3, img, p) => (
    <div className="whyus-card">
      <h3>{h3}</h3>
      <img alt="why lema" src={img} />
      <p>{p}</p>
    </div>
  );
  return (
    <div className="whyus" id="whyus">
      <div className="section-header">
        <h2>Why Lema</h2>
      </div>
      <div className="whyus-container">
        {card(
          "Expertise",
          expert,
          " Our team of experienced insurance and investment professionals ensures you receive expert guidance."
        )}
        {card(
          "Affordability",
          afford,
          "We offer competitive rates to suit your budget."
        )}
        {card(
          "Innovation",
          innovate,
          "We stay at the forefront of industry trends to provide innovative solutions."
        )}
        {card(
          "Honesty",
          honest,
          "We maintain transparent and honest relationships with our clients."
        )}
        {card(
          "Client-Centric",
          client,
          "Your needs are our priority, and we tailor our services to meet them."
        )}
      </div>
    </div>
  );
}
