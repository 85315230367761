import "./App.css";
// import Hero from "./sections/hero/Hero";
import Header from "./components/header/Header";
// import About from "./sections/about/About";
// import Services from "./sections/services/Services";
// import Whyus from "./sections/whyus/Whyus";
// import Cta from "./sections/cta/Cta";
// import Partners from "./sections/partners/Partners";
// import Objectives from "./sections/objectives/Objectives";

import Footer from "./components/footer/Footer";
import { useEffect, useState } from "react";
// import Bio from "./sections/about/Bio";
// import AboutSection from "./sections/about/AboutSection";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/home/Home";
import ServicesPage from "./pages/services/ServicesPage";

function App() {
  const [activeSection, setActiveSection] = useState("hero");
  const [isMenuActive, setIsMenuActive] = useState(false);

  const [isBio, setIsBio] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      // Find the section that is currently in the viewport
      const sections = [
        "hero",
        "about",
        "services",
        "whyus",
        "bio",
        "partners",
        "cta",
        "footer",
      ];
      let found = false;
      for (const sectionId of sections) {
        const section = document.getElementById(sectionId);
        if (section) {
          const rect = section.getBoundingClientRect();
          const windowHeight =
            window.innerHeight || document.documentElement.clientHeight;

          if (rect.top <= windowHeight / 2 && rect.bottom >= windowHeight / 2) {
            if (activeSection !== sectionId) {
              setActiveSection(sectionId);
            }
            found = true;
            break;
          }
        }
      }

      // If no section is found, set the default active section
      // if (!found) {
      //   setActiveSection("hero");
      // }
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up event listeners on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [activeSection]);

  const scrollToSection = (sectionId) => {
    setIsMenuActive(false);
    const section = document.getElementById(sectionId);

    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const [isHomePage, setIsHomePage] = useState(true);
  useEffect(() => {
    console.log("running");
    if (window.location.pathname === "/#" || window.location.pathname === "/") {
      setIsHomePage(true);
    } else {
      setIsHomePage(false);
    }
  }, [isHomePage]);

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [isHomePage]);
  return (
    <div className="App">
      <BrowserRouter>
        <Header
          scrollToSection={scrollToSection}
          activeSection={activeSection}
          setIsMenuActive={setIsMenuActive}
          isMenuActive={isMenuActive}
          setIsBio={setIsBio}
          isBio={isBio}
          isHomePage={isHomePage}
          setIsHomePage={setIsHomePage}
        />
        <Routes>
          <Route
            index
            path="/"
            element={<Home isBio={isBio} setIsBio={setIsBio} />}
          />
          <Route index path="/services" element={<ServicesPage />} />
        </Routes>
        <Footer setIsHome={setIsHomePage} isHome={isHomePage} />
      </BrowserRouter>

      {/*     
      <Hero />
      <Objectives />
      <AboutSection setIsBio={setIsBio} isBio={isBio} />
      <Partners />
      <Services scrollToSection={scrollToSection} /> */}
      {/* <ServicesX /> */}
      {/* <Whyus /> */}
      {/* <Cta /> */}
    </div>
  );
}

export default App;
