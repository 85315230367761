import React from "react";
import "./Hero.css";
import lemaImage from "../../assets/img/lema-icon.png";
// import lemaIcon from "../../assets/img/lema-icon.png";
import herovid from "../../assets/vid/hero.mp4";

export default function Hero() {
  return (
    <div className="hero" id="hero">
      {/* MAIN-BANNER */}
      <div className="main-banner" id="top">
        <video autoPlay muted loop id="bg-video">
          <source src={herovid} type="video/mp4" />
        </video>
      </div>
      <div className="hero-content">
        <div className="hero-center">
          <h1>Lema Insurance</h1>
          <p className="hero-subtitle">
            Honesty. Innovation. Affordability. Your Insurance, Our Commitment
          </p>
        </div>
        <div className="hero-footer">
          {/* <div className="hero-white-bg">
            <img alt="logo" src={lemaImage} className="lema-logo" />
            <div className="hero-white-bg-content">
              <h2>LEMA CORE VALUES</h2>
              <ul>
                <li>
                  We guarantee that any compensation in any eventuality will be
                  as promised.
                </li>
                <li>
                  We offer insurance and investment products tailored to your
                  specific needs and means.
                </li>
                <li>
                  We commit to getting you the best coverage at the best rates.
                </li>
              </ul>
            </div>
          </div> */}
          {/* <div className="hero-no-div">
            <h2>20,000+</h2>
            <p>Happy clients, and counting..</p>
          </div> */}
        </div>
      </div>
    </div>
  );
}
