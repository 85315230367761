import React from "react";
import { useEffect, useState } from "react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import AboutSection from "../../sections/about/AboutSection";
import Hero from "../../sections/hero/Hero";
import Objectives from "../../sections/objectives/Objectives";
import Partners from "../../sections/partners/Partners";
import Whyus from "../../sections/whyus/Whyus";
import ServicesX from "../../sections/services/ServicesX";
import Services from "../../sections/services/Services";
import About from "../../sections/about/About";
import Bio from "../../sections/about/Bio";

export default function Home({ isBio, setIsBio }) {
  const [activeSection, setActiveSection] = useState("hero");
  const [isMenuActive, setIsMenuActive] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      // Find the section that is currently in the viewport
      const sections = ["hero", "about", "services", "whyus", "cta"];
      let found = false;
      for (const sectionId of sections) {
        const section = document.getElementById(sectionId);
        if (section) {
          const rect = section.getBoundingClientRect();
          const windowHeight =
            window.innerHeight || document.documentElement.clientHeight;

          if (rect.top <= windowHeight / 2 && rect.bottom >= windowHeight / 2) {
            if (activeSection !== sectionId) {
              setActiveSection(sectionId);
            }
            found = true;
            break;
          }
        }
      }

      // If no section is found, set the default active section
      if (!found) {
        setActiveSection("hero");
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up event listeners on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [activeSection]);

  const scrollToSection = (sectionId) => {
    setIsMenuActive(false);
    const section = document.getElementById(sectionId);

    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div>
      {/* <Header
        scrollToSection={scrollToSection}
        activeSection={activeSection}
        setIsMenuActive={setIsMenuActive}
        isMenuActive={isMenuActive}
        setIsBio={setIsBio}
      /> */}
      <Hero />
      <Objectives />
      <AboutSection setIsBio={setIsBio} isBio={isBio} />
      {/* <About scrollToSection={scrollToSection} /> */}

      <Services scrollToSection={scrollToSection} />
      <Whyus />
      <Partners />
      {/* <Bio /> */}
      {/* <ServicesX /> */}
      {/* <Cta /> */}
      {/* <Footer /> */}
    </div>
  );
}
