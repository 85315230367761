import React from "react";
import "./Objectives.css";
import LocationSearchingIcon from "@mui/icons-material/LocationSearching";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import CrisisAlertIcon from "@mui/icons-material/CrisisAlert";

export default function Objectives() {
  return (
    <section id="about-boxes" className="about-boxes">
      <div className="container" data-aos="fade-up">
        <div className="row">
          <div
            className="col-lg-4 col-md-6 d-flex align-items-stretch"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div className="card">
              <img
                src="https://images.pexels.com/photos/8439663/pexels-photo-8439663.jpeg?auto=compress&cs=tinysrgb&w=800"
                className="card-img-top"
                alt="lema Vision"
              />
              <div className="card-icon">
                <ImageSearchIcon className="icon" />
              </div>
              <div className="card-body">
                <h5 className="card-title">
                  <a href="">Our vision</a>
                </h5>
                <p className="card-text">
                  To build long term client relationships by providing quality
                  and affordable insurance products & great investment solutions
                  backed by a vetted, dependable network of insurance &
                  financial institutions.
                </p>
              </div>
            </div>
          </div>
          <div
            className="col-lg-4 col-md-6 d-flex align-items-stretch"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <div className="card">
              <img
                src="https://images.pexels.com/photos/1181519/pexels-photo-1181519.jpeg?auto=compress&cs=tinysrgb&w=800"
                className="card-img-top"
                alt="lema Goal"
              />
              <div className="card-icon">
                <CrisisAlertIcon className="icon" />
              </div>
              <div className="card-body">
                <h5 className="card-title">
                  <a href="">Our Goal</a>
                </h5>
                <p className="card-text">
                  To offer tailor made insurance, investment and pension
                  products to our clients backed by a responsive customer
                  service, fast claim settlements and short turnaround times on
                  investment payouts.
                </p>
              </div>
            </div>
          </div>
          <div
            className="col-lg-4 col-md-6 d-flex align-items-stretch"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            <div className="card">
              <img
                src="https://images.pexels.com/photos/2312369/pexels-photo-2312369.jpeg?auto=compress&cs=tinysrgb&w=800"
                className="card-img-top"
                alt="lema Mission"
              />
              <div className="card-icon">
                <LocationSearchingIcon className="icon" />
              </div>
              <div className="card-body">
                <h5 className="card-title">
                  <a href="">Our Mission</a>
                </h5>
                <p className="card-text">
                  To help our clients mitigate risks in life occasioned by
                  unforeseen accidents & health issues at all stages and under
                  all considered circumstances and to help enhance and secure
                  their financial status.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
