import React from "react";
import "./Bio.css";

export default function Bio({ setIsBio }) {
  return (
    <div className="bio" id="bio">
      <div className="bio-content">
        <div className="bio-left">
          <div className="bio-image"></div>
          <button onClick={() => setIsBio(false)}>About LEMA</button>
        </div>
        <div className="bio-right">
          <div className="section-header">
            <h2>About Our Founder & CEO </h2>
          </div>
          <div>
            <p>
              Leo Matundura has been an influential figure in the insurance
              sector with over four decades of experience Mr. Matundura has
              contributed to the industry's growth through various honorary
              positions; serving as chairman of the Association of Insurance
              Brokers of Kenya as long as being a board member of the esteemed
              Insurance Training and Education Trust.
            </p>
          </div>
          <p>
            He continues to provide sound leadership to the company; focusing on
            technical development of our insurance processes and enhancement of
            our client’s experience with the insurance services we provide.
          </p>
        </div>
      </div>
    </div>
  );
}
