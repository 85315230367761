import "./Footer.css";
import "../../assets/css/variables.css";
import { Link } from "react-router-dom";
import logo from "../../assets/img/lema.png";

export default function Footer({ setIsHome, isHome }) {
  return (
    <footer id="footer" className="footer" style={{ color: "#3a326b" }}>
      <div className="footer-content">
        <div className="container">
          <div className="row d-flex justify-content-around align-items-center">
            <div className="col-lg-5 col-md-6">
              <div className="footer-info">
                <h3>Lema Insurance</h3>
                <p style={{ color: "#000" }}>
                  Josem Trust Place, 2nd Floor <br />
                  Bunyala Road, Nairobi
                  <br />
                  P. O. Box 12926 <br />
                  00400 Nairobi
                  <br />
                </p>
                <strong>Email: </strong>
                <a
                  href={`mailto:info@lemainsurance.com?subject=REQUEST FOR DETAILS&body=${encodeURIComponent(
                    "Dear sir/madam\n\nI am <Your Full Name>, and I am interested in getting quotation/details..."
                  )}`}
                >
                  info@lemainsurance.com
                </a>
                <br />
                <strong>Phone: </strong> +254731567183
              </div>
            </div>

            <div className="col-lg-3 col-md-6 footer-links">
              <h4>Quick Links</h4>
              <ul>
                <li>
                  <i className="bi bi-chevron-right"></i>{" "}
                  {isHome ? <a href="/">Home</a> : <Link to="/">Home</Link>}
                </li>
                <li>
                  <i className="bi bi-chevron-right"></i>{" "}
                  {isHome ? (
                    <a href="/#about">About us</a>
                  ) : (
                    <Link to="/">About</Link>
                  )}
                </li>
                <li>
                  <i className="bi bi-chevron-right"></i>
                  <a href="#services">Services</a>
                </li>
              </ul>
            </div>

            <div className="col-lg-2 col-md-6 footer-links">
              <img src={logo} style={{ width: 300 }} />
            </div>
          </div>
        </div>
      </div>

      <div className="footer-legal text-center">
        <div className="container d-flex flex-column flex-lg-row justify-content-center justify-content-lg-between align-items-center">
          <div className="d-flex flex-column align-items-center align-items-lg-start">
            <div className="copyright d-flex justify-content-between">
              <p>
                &copy; Copyright{" "}
                <strong>
                  <span>Lema Insurance</span>
                </strong>
                . All Rights Reserved
              </p>
              <p
                style={{
                  color: "#3a326b",
                  textAlign: "center",
                  fontWeight: "600",
                  marginLeft: 50,
                }}
              >
                Regulated by IRA
              </p>
            </div>

            <div className="credits">
              Designed by{" "}
              <a href="https://www.linkedin.com/in/lumona-mulengwa-117909165/">
                Lumona
              </a>
            </div>
          </div>

          <div className="social-links order-first order-lg-last mb-3 mb-lg-0">
            {/* <a href="#" className="twitter">
              <i className="bi bi-twitter"></i>
            </a>
            <a href="#" className="facebook">
              <i className="bi bi-facebook"></i>
            </a>
            <a href="#" className="instagram">
              <i className="bi bi-instagram"></i>
            </a>
            <a href="#" className="google-plus">
              <i className="bi bi-skype"></i>
            </a>
            <a href="#" className="linkedin">
              <i className="bi bi-linkedin"></i>
            </a> */}
          </div>
        </div>
      </div>
    </footer>
  );
}
