import About from "./About";
import Bio from "./Bio";
import "./AboutSection.css";

export default function AboutSection({ isBio, setIsBio }) {
  return (
    <div id="aboutsection" className={`aboutsection ${isBio && "bio-active"}`}>
      <div className="aboutsection-about">
        <About setIsBio={setIsBio} />
      </div>
      <div className={`aboutsection-bio`}>
        <Bio setIsBio={setIsBio} />
      </div>
    </div>
  );
}
