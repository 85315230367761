import React, { useEffect, useState } from "react";
import "./Header.css";
import logo from "../../assets/img/lema.png";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";

export default function Header({
  scrollToSection,
  activeSection,
  setIsMenuActive,
  isMenuActive,
  setIsBio,
  isBio,
  isHomePage,
  setIsHomePage,
}) {
  const [isMobileDevice, setIsMobileDevice] = useState(false);

  {
    console.log("from header: " + isHomePage);
  }
  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMobileDevice(true);
    } else {
      setIsMobileDevice(false);
    }

    const updateStates = () => {
      if (window.innerWidth < 768) {
        setIsMobileDevice(true);
      } else {
        setIsMobileDevice(false);
      }
    };
    window.addEventListener("resize", updateStates);
  }, [activeSection]);

  useEffect(() => {
    setIsMenuActive(false);
  }, [isHomePage, setIsMenuActive]);

  return (
    <div className="header">
      <img alt="logo" src={logo} className="logo" />
      {!isMobileDevice ? (
        <nav>
          {isHomePage ? (
            <div
              onClick={() => scrollToSection("hero")}
              className={
                activeSection === "hero" ? "nav-option active" : "nav-option"
              }
            >
              Home
            </div>
          ) : (
            <Link
              to={"/"}
              className={isHomePage ? "nav-option active" : "nav-option"}
              onClick={() => setIsHomePage(true)}
            >
              Home
            </Link>
          )}
          {isHomePage && (
            <div
              onClick={() => {
                scrollToSection("about");
                setIsBio(false);
              }}
              className={
                !isBio && activeSection === "about"
                  ? "nav-option active"
                  : "nav-option"
              }
            >
              About Us
            </div>
          )}
          {isHomePage && (
            <div
              onClick={() => {
                scrollToSection("about");
                !isBio && setIsBio(true);
              }}
              className={
                // activeSection === "bio" ? "nav-option active" : "nav-option"
                (isBio && activeSection === "bio") ||
                (isBio && activeSection === "about")
                  ? "nav-option active"
                  : "nav-option"
              }
            >
              Founder
            </div>
          )}

          <div
            className={
              activeSection === "services" ? "nav-option active" : "nav-option"
              // !isHomePage ? "nav-option active" : "nav-option"
            }
            onClick={() => scrollToSection("services")}
          >
            Services
          </div>
          {isHomePage && (
            <div
              onClick={() => scrollToSection("whyus")}
              className={
                activeSection === "whyus" ? "nav-option active" : "nav-option"
              }
            >
              Why Us
            </div>
          )}
          {isHomePage && (
            <div
              onClick={() => {
                scrollToSection("partners");
                setIsBio(true);
              }}
              className={
                activeSection === "partners"
                  ? "nav-option active"
                  : "nav-option"
              }
            >
              Partners
            </div>
          )}

          {/* <div
            onClick={() => {
              scrollToSection("footer");
            }}
            className={
              activeSection === "footer" ? "nav-option active" : "nav-option"
            }
          >
            Contact us
          </div> */}
          <a
            onClick={() => scrollToSection("cta")}
            className={
              activeSection === "cta" ? "nav-option active" : "nav-option"
            }
            href={`mailto:info@lemainsurance.com?subject=REQUEST FOR DETAILS&body=${encodeURIComponent(
              "Dear sir/madam\n\nI am <Your Full Name>, and I am interested in getting quotation/details..."
            )}`}
            target={"_blank"}
          >
            Contact Us
          </a>
        </nav>
      ) : (
        <div
          className="nav-option"
          onClick={() => setIsMenuActive((current) => !current)}
        >
          <MenuIcon />
          {isMenuActive && (
            <nav className="nav-mobile">
              {isHomePage ? (
                <div
                  onClick={() => {
                    scrollToSection("hero");
                    setIsMenuActive((current) => !current);
                  }}
                  className={
                    activeSection === "hero"
                      ? "nav-option active"
                      : "nav-option"
                  }
                >
                  Home
                </div>
              ) : (
                <Link
                  to={"/"}
                  className={isHomePage ? "nav-option active" : "nav-option"}
                  onClick={() => {
                    setIsHomePage(true);
                    setIsMenuActive((current) => !current);
                  }}
                >
                  Home
                </Link>
              )}
              {isHomePage && (
                <div
                  onClick={() => {
                    scrollToSection("about");
                    setIsBio(false);
                    setIsMenuActive((current) => !current);
                  }}
                  className={
                    !isBio && activeSection === "about"
                      ? "nav-option active"
                      : "nav-option"
                  }
                >
                  About Us
                </div>
              )}
              {isHomePage && (
                <div
                  onClick={() => {
                    scrollToSection("about");
                    !isBio && setIsBio(true);
                    setIsMenuActive((current) => !current);
                  }}
                  className={
                    // activeSection === "bio" ? "nav-option active" : "nav-option"
                    (isBio && activeSection === "bio") ||
                    (isBio && activeSection === "about")
                      ? "nav-option active"
                      : "nav-option"
                  }
                >
                  Founder
                </div>
              )}
              <div
                className={
                  activeSection === "services"
                    ? "nav-option active"
                    : "nav-option"
                  // !isHomePage ? "nav-option active" : "nav-option"
                }
                onClick={() => {
                  scrollToSection("services");
                  setIsMenuActive((current) => !current);
                }}
              >
                Services
              </div>

              {isHomePage && (
                <div
                  onClick={() => {
                    scrollToSection("whyus");
                    setIsMenuActive((current) => !current);
                  }}
                  className={
                    activeSection === "whyus"
                      ? "nav-option active"
                      : "nav-option"
                  }
                >
                  Why Us
                </div>
              )}
              {isHomePage && (
                <div
                  onClick={() => {
                    scrollToSection("partners");
                    setIsMenuActive((current) => !current);
                  }}
                  className={
                    activeSection === "partners"
                      ? "nav-option active"
                      : "nav-option"
                  }
                >
                  Partners
                </div>
              )}
              <div
                onClick={() => {
                  scrollToSection("footer");
                  setIsMenuActive((current) => !current);
                }}
                className={
                  activeSection === "footer"
                    ? "nav-option active"
                    : "nav-option"
                }
              >
                Get In Touch
              </div>
            </nav>
          )}
        </div>
      )}
    </div>
  );
}
