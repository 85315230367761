import React from "react";
import "./About.css";

export default function About({ setIsBio }) {
  return (
    <div className="about" id="about">
      <div className="about-content">
        <div className="about-left"></div>
        <div className="about-right">
          <div className="section-header">
            <h2>About us</h2>
          </div>
          <div>
            <p>
              Lema Insurance Brokers Limited is a medium sizzed insurance
              brokerage firm headquartered in Nairobi, Kenya
            </p>
            <p>
              Established in 1983 under the Companies Act (Cap. 486) in the
              Republic of Kenya, our company is duly registered by the Insurance
              Regulatory Authority, we began operations in 1994 and have been
              offering unique insurance, pensions and investments solutions to
              out esteemed client ever since.
            </p>
            <p>
              We are pioneer members of the Association of Insurance Brokers of
              Kenya and our founder and chairman, Leo Obweri Matundura served as
              the chairman of this esteemed institution.
            </p>
            <button onClick={() => setIsBio(true)}>About the Founder</button>
          </div>
        </div>
      </div>
    </div>
  );
}
